/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

// any CSS you import will output into a single css file (app.css in this case)
import './styles/app.css';

import 'infinite-scroll';
import 'jquery';

const $ = require('jquery');
const jQueryBridget = require('jquery-bridget');
const InfiniteScroll = require('infinite-scroll');

// make Infinite Scroll a jQuery plugin
jQueryBridget( 'infiniteScroll', InfiniteScroll, $ );

// create global $ and jQuery variables
global.$ = global.jQuery = $;

import './bootstrap';
